<template>
  <form class="form">
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h5 class="card-label font-weight-bolder">
            {{ $t("Sources") }}
          </h5>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("List") }}</span>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col>
            <datatable ref="sourcesTable" :table-props="tableProps" :total="total" :fields="fields"
              :options.sync="tableOptions" :per-page-options="perPageOptions">
              <template #[`cell.administrative_structures`]="{ item }">
                {{ getAdmistrativeStructure(item.administrative_structures[0]) }}
              </template>
              <template #[`cell.territorial_jurisdiction`]="{ item }">
                <country-flag class="mr-4" :country-iso="item.territorial_jurisdiction[0]"></country-flag>
              </template>
              <template #[`cell.active`]="{ item }">
                <b-badge variant="primary" class="text-light">{{
                  item.active == true ? "ACTIVE" : item.active
                }}</b-badge>
              </template>
              <template #[`cell.frequency`]="{ item }">
                {{ item.frequency }}
              </template>
              <template #[`cell.changedetection_last_changed`]="{ item }">
                {{ moment(item.changedetection_last_changed).format(date_format)
                }}
              </template>
              <template #[`cell.actions`]="{ item }">
                <div class="d-flex">
                  <btn-icon icon="flaticon2-edit" :tooltip="$t('Edit')" class="mx-2" size="xs"
                    @click="$router.push({ name: 'detail-source', params: { sourceID: item.id } })"></btn-icon>
                  <btn-icon icon="flaticon-delete-1" :tooltip="$t('Delete')" size="xs" @click="remove(item)"></btn-icon>
                </div>
              </template>
            </datatable>
          </b-col>
        </b-row>
      </div>
    </div>
  </form>
</template>

<script>
import icons from "@/core/config/icons.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    CountryFlag,
  },

  data() {
    return {
      icons,
      total: 0,
      status: null,
      isLoading: false,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      items: [],
      selectableStatus: ["approved", "cancelled", "draft", "rejected", "submitted"],
      fields: [
        { label: this.$t("Name"), key: "name" },
        { label: this.$t("Url"), key: "url" },
        { label: this.$t("Description"), key: "description" },
        { label: this.$t("Frequence"), key: "frequency" },
        { label: this.$t("Administrative Str."), key: "administrative_structures" },
        { label: this.$t("Territorial Jurisdiction"), key: "territorial_jurisdiction" },
        { label: this.$t("Status"), key: "active" },
        { label: this.$t("Last changed"), key: "changedetection_last_changed" },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" }
      ],
    };
  },

  computed: {
    // State
    ...mapState("source", ["sources"]),
    ...mapState("npg", ["administrativeStructure"]),
    // Getters
    ...mapGetters("user", ["date_format"]),

    tableProps() {
      return {
        busy: this.isLoading,
        items: this.sources,
        fields: this.fields,
      };
    },
    currentDateFormat: {
      get() {
        return this.date_format;
      },
      set(val) {
        this.$store.dispatch("user/setUserDateFormat", val);
      },
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Pubblications") },
      { title: this.$t("Source") },
    ]);
  },

  methods: {
    moment,
    getAdmistrativeStructure(id) {
      if (this.administrativeStructure) {
        const structure = this.administrativeStructure
        for (let index = 0; index < structure.length; index++) {
          if (structure[index].id == id) {
            return structure[index].name;
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.categories-cell {
  max-width: 12ch;
}

.categories-span {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
