var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h5', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sources")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("List")))])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', [_c('datatable', {
    ref: "sourcesTable",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.administrative_structures",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.getAdmistrativeStructure(item.administrative_structures[0])) + " ")];
      }
    }, {
      key: "cell.territorial_jurisdiction",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('country-flag', {
          staticClass: "mr-4",
          attrs: {
            "country-iso": item.territorial_jurisdiction[0]
          }
        })];
      }
    }, {
      key: "cell.active",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('b-badge', {
          staticClass: "text-light",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(item.active == true ? "ACTIVE" : item.active))])];
      }
    }, {
      key: "cell.frequency",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.frequency) + " ")];
      }
    }, {
      key: "cell.changedetection_last_changed",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.moment(item.changedetection_last_changed).format(_vm.date_format)) + " ")];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('btn-icon', {
          staticClass: "mx-2",
          attrs: {
            "icon": "flaticon2-edit",
            "tooltip": _vm.$t('Edit'),
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'detail-source',
                params: {
                  sourceID: item.id
                }
              });
            }
          }
        }), _c('btn-icon', {
          attrs: {
            "icon": "flaticon-delete-1",
            "tooltip": _vm.$t('Delete'),
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        })], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }