<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="text-center">
        <h1 class="mt-4 mb-8">Change focus and redirect</h1>
        {{ this.$route.query.redirect }}
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/core/services/event/eventBus";
export default {
  mounted() {
    if (this.$route.query.hasOwnProperty('focus')) {
      eventBus.$emit('change-focus', Number(this.$route.query.focus), { path: this.$route.query.redirect })
    }
  }
}
</script>