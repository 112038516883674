var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "mt-4 mb-8"
  }, [_vm._v("Change focus and redirect")]), _vm._v(" " + _vm._s(this.$route.query.redirect) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }